<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }}  application status</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="visa_application_status.title"
                  :error="$v.visa_application_status.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.visa_application_status.title.$error">Title is required</span>
              <span class="text-danger" v-if="errors.title">* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                  label="Application Type"
                  v-model="visa_application_status.application_type"
                  :items="application_types"
                  outlined
                  dense
                  item-text="title"
                  item-value="value"
              >
              </v-select>
              <span class="text-danger" v-if="$v.visa_application_status.application_type.$error">Application type is required</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-autocomplete
                  :loading="countryLoading"
                  :error="$v.visa_application_status.country_id.$error"
                  :filter="customFilter"
                  :items="availableCountries"
                  @change="changeCountry()"
                  dense
                  item-text="name"
                  item-value="id"
                  outlined
                  prepend-inner-icon="fas fa-globe-americas"
                  return-object
                  v-model="country"
              >
                <template v-slot:label>
                  Country <span class="text-danger">*</span>
                </template>
                <template v-slot:item="slotProps">
                  <i :class="`em em-flag-${slotProps.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{ slotProps.item.title }}
                </template>
                <template v-slot:selection="data">
                  <i :class="`em em-flag-${data.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{ data.item.title }}
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.visa_application_status.country_id.$error">Country is required</span>
              <span class="text-danger" v-if="errors.country_id">* {{ errors.country_id[0] }}</span>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="visa_application_status.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="errors.description">* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="visa_application_status.is_active"
                  :label="visa_application_status.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            v-if="checkIsAccessible('visa-application-status', 'create') || checkIsAccessible('visa-application-status', 'edit')"
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import CountryService from "@/services/country/CountryService";
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";

const visaApplicationStatus = new VisaApplicationStatusService();
const country = new CountryService();

export default {
  name: "CreateAndUpdate",
  props:["availableCountries"],
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      countryLoading: false,
      errors: [],
      country: null,
      application_types: [
        {title: 'Academic Processing', value: "academic"},
        {title: 'Visa Processing', value: "visa"},
        {title: 'Candidate inquiry info', value: "candidate_inquiry_info"},
      ],
      visa_application_status: {
        title: '',
        sub_title: '',
        position: '',
        country_id: '',
        email_template_id: '',
        description: '',
        is_active: true,
        is_default: false,
        application_type:null
      },

      editorConfig: {
        versionCheck: false,
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'Source', '-', 'Save', 'NewPage', 'DocProps', 'Preview', 'Print', '-', 'Templates'],
        ]
      }
    }
  },
  validations: {
    visa_application_status: {
      title: {required},
      country_id: {required},
      application_type: {required},
    }
  },
  mounted() {

  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$v.$reset();
      this.resetForm();
    },
    createVisaApplicationStatus() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editVisaApplicationStatus(visa_application_status) {
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.visa_application_status = visa_application_status;
      if (visa_application_status.country_id != null) {
        this.country = visa_application_status.country_id;
      }
    },


    changeCountry() {
      this.visa_application_status.country_id = this.country.id;
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.title.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      visaApplicationStatus
          .create(this.visa_application_status)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Visa Application Status created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      visaApplicationStatus
          .update(this.visa_application_status.id, this.visa_application_status)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Visa Application Status updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm() {
      this.$v.$reset();
      this.country = null;
      this.visa_application_status = {
        title: '',
        sub_title: '',
        position: '',
        country_id: '',
        email_template_id: '',
        description: '',
        is_active: true,
        is_default: false
      }
    }
  }
}
</script>

<style>
@import 'https://emoji-css.afeld.me/emoji.css';
</style>
