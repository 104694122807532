<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Visa Application Status</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Visa Application Status
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('visa-application-status', 'create')"
                      @click="createVisaApplicationStatus()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Visa Application Status
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Title"
                      v-model="search.title"
                      v-on:keyup.enter="searchVisaApplicationStatus"
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Country"
                      v-model="search.country_id"
                      v-on:keyup.enter="searchVisaApplicationStatus"
                      @input="search.country_id = $event !== null ? $event : ''"
                      :items="searchCountries"
                      item-text="title"
                      item-value="id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchVisaApplicationStatus"
                      @input="search.is_active = $event !== null ? $event : ''"
                      :items="status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Application Type"
                      v-model="search.application_type"
                      v-on:keyup.enter="searchVisaApplicationStatus"
                      @input="search.application_type = $event !== null ? $event : ''"
                      :items="document_types"
                      item-text="title"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      @click.prevent="searchVisaApplicationStatus"
                      v-on:keyup.enter="searchVisaApplicationStatus"
                      class="btn btn-primary"
                      :loading="loading"
                  >
                    <v-icon small outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Application Type</strong></th>
                  <th><strong>Country</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="visaApplicationStatuses" @change="sort" @start="drag=true"
                             tag="tbody" @end="drag=false">
                    <tr v-for="(visaApplicationStatus, index) in visaApplicationStatuses" :key="index">
                      <td>
                        <a @click="editVisaApplicationStatus(visaApplicationStatus)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          <i class="fa fa-sort"></i> {{ visaApplicationStatus.title }}
                        </a>
                      </td>
                      <td>
                        <span
                            class="badge badge-primary text-uppercase">{{ visaApplicationStatus.application_type }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bolder text-dark-65">
                         {{ visaApplicationStatus.country ? visaApplicationStatus.country : '--' }}
                        </span>
                      </td>
                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': visaApplicationStatus.is_active, 'badge-danger': !visaApplicationStatus.is_active }"
                        >{{ visaApplicationStatus.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('visa-application-status', 'show')">
                                <a class="navi-link" @click="manageVisaApplicationStatusState(visaApplicationStatus.id)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-cogs</v-icon>
                                  </span>
                                  <span class="navi-text">Manage Status State</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('visa-application-status', 'edit')">
                                <a class="navi-link" @click="editVisaApplicationStatus(visaApplicationStatus)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('application-status', 'delete')">
                                <a class="navi-link"
                                   @click.prevent="deleteVisaApplicationStatus(visaApplicationStatus.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="visaApplicationStatuses.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="5">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update :available-countries="searchCountries" ref="create-and-update" @refresh="getAllVisaApplicationStatuses"></create-and-update>

    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/visa/application-status/CreateAndUpdate";
import draggable from "vuedraggable";
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";
import ApplicationStatusState from "./application-status-state/Index.vue";
import CountryService from "@/services/country/CountryService";

const country = new CountryService();
const visaApplicationStatus = new VisaApplicationStatusService();

export default {
  components: {
    CreateAndUpdate, ApplicationStatusState,
    draggable
  },
  data() {
    return {
      visaApplicationStatuses: [],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isSorting: false,
      application_status: null,
      application_status_state_dialog: false,
       document_types: [
        {title: "Academic", value: "academic"},
        {title: "Visa", value: "visa"}
      ],
      status: [
        {name: 'All', value: ''},
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search: {
        title: '',
        is_active: '',
      },
      countrySearch:{
        is_operating_country: 1
      },
      searchCountries:[],
    }
  },
  mounted() {
    this.getAllVisaApplicationStatuses();
    this.getCountries()
  },
  methods: {
    createVisaApplicationStatus() {
      this.$refs['create-and-update'].createVisaApplicationStatus();
    },
    editVisaApplicationStatus(visaApplicationStatus) {
      this.$refs['create-and-update'].editVisaApplicationStatus(visaApplicationStatus);
    },
    getCountries() {
      country
          .getAllCountry(this.countrySearch)
          .then((response) => {
            this.searchCountries = response.data.data;
          })
          .catch((error) => {
          })
    },
    getAllVisaApplicationStatuses() {
      this.loading = true;
      visaApplicationStatus
          .paginate(this.search, this.page)
          .then(response => {
            this.visaApplicationStatuses = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    sort() {
      this.isSorting = true;
      visaApplicationStatus
          .sort(this.visaApplicationStatuses)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Visa Application Status Sorted !!');
            this.getAllVisaApplicationStatuses()
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchVisaApplicationStatus() {
      this.getAllVisaApplicationStatuses();
    },
    deleteVisaApplicationStatus(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            visaApplicationStatus
                .delete(id)
                .then((response) => {
                  this.getAllVisaApplicationStatuses()
                  this.$snotify.success("Visa Application Status Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    manageVisaApplicationStatusState(id) {
      this.$router.push({name: 'visa-application-status-state', params: {id:id}})
    }
  }
}
</script>